import axios from 'axios';
import Vue from 'vue';
import '@/lib/log';
import { computed, ref } from '@vue/composition-api';
import { BaseItemState, BaseUseItemModel } from '@/module/shared/base.use-crud.interface';
import { UserModel } from './user.interface';
import { UserMessageError } from '@/lib/user-message-error';
import { GetItemsOptions } from '../common';

const apiPath = `${process.env.VUE_APP_API_BASE_URL}/workspace-ui/admin/user`;
const defaultMaxResultLimit = -1;

export const emptyItem = (): UserModel => {
  return {
    uuid: '',
    userId: '',
    organizationId: '',
    state: '',
    customerId: '',
    roles: [],
    five9AgentUsername: '',
    five9Token: '',
    five9AgentId: '',
    customerCreatedAt: '',
    createdAt: '',
    updatedAt: ''
  };
};

export const state: BaseItemState<UserModel> = {
  items: ref<UserModel[]>([]),
  selectedItemId: ref(''),
  selectedItem: ref(emptyItem()),
  createdItemId: ref(''),
  createdItem: ref(emptyItem()),
  isLoading: ref(false)
};

export function useUserApi(
  useGlobalQueryParams: Record<any, any> | undefined = undefined
): BaseUseItemModel<UserModel> & any {
  Vue.$log.debug('Loaded with query', useGlobalQueryParams);

  const findItem = (uuid: string): UserModel | undefined => {
    if (!uuid) {
      return undefined;
    }
    Vue.$log.debug(`Finding item ${uuid} from items: `, state.items.value);
    const item = state.items.value.find((i: UserModel) => i.uuid === uuid);
    if (!item) {
      return undefined;
    }
    return item;
  };

  const selectItem = async (uuid: string): Promise<UserModel | undefined> => {
    if (!uuid) {
      Vue.$log.debug(`Setting selected item to empty because ${uuid} was given`);
      state.selectedItemId.value = '';
      state.selectedItem.value = emptyItem();
      return;
    }
    Vue.$log.debug(`Selecting item ${uuid} from items: `, state.items.value);
    const item = findItem(uuid);
    if (!item) {
      Vue.$log.debug(`Item ${uuid} not found`);
      return undefined;
    }
    Vue.$log.debug(`Selected item ${uuid}`, item);
    state.selectedItemId.value = item?.uuid ? item.uuid : '';
    state.selectedItem.value = item;
    return state.selectedItem.value;
  };

  const selectItemBy = async (key: string, value: string): Promise<UserModel | undefined> => {
    if (!key || !value) {
      Vue.$log.debug('selectItemBy: Key or value is missing');
      return undefined;
    }
    Vue.$log.debug(`Selecting item ${value} from items: `, state.items.value);
    const item = state.items.value.find((i: any) => i?.[key] === value);
    if (!item) {
      Vue.$log.debug(`Selected item by ${key} not found`);
      return undefined;
    }
    state.selectedItemId.value = item?.uuid ? item.uuid : '';
    Vue.$log.debug(`Selected item by ${key}`, item);
    state.selectedItem.value = item;
    return state.selectedItem.value;
  };

  const getItems = async (options?: GetItemsOptions): Promise<UserModel[] | undefined> => {
    state.isLoading.value = true;
    const combinedQueryParams = Object.assign(
      {},
      {
        limit: defaultMaxResultLimit
      },
      useGlobalQueryParams,
      options?.query
    );
    Vue.$log.debug('User: Fetching items with query params: ', combinedQueryParams);
    return axios
      .get(apiPath, { params: combinedQueryParams })
      .then((res) => {
        Vue.$log.debug('Returned from API: ', res.data);
        // Update the list of items.
        state.items.value = res.data?._embedded ? res.data._embedded : res.data;
        // Update the selected item.
        if (state.selectedItem?.value?.uuid !== undefined) {
          for (const item of state.items.value) {
            if (item?.uuid === state.selectedItem.value.uuid) {
              state.selectedItem.value = item;
            }
          }
        }
        return options?.raw ? res.data : state.items.value;
      })
      .catch((err) => {
        const error = new UserMessageError(`Error communicating with the API.`, err);
        const errMessage = 'Error loading.';
        Vue.$log.error(errMessage, err);
        return error;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const addOrReplaceItemInItems = (newItem: UserModel): void => {
    if (state.items.value.some((item: UserModel) => item.uuid === newItem.uuid)) {
      state.items.value.forEach((item: UserModel, i: number) => {
        if (item.uuid === newItem.uuid) {
          Vue.$log.debug(`Replacing item in items: ${newItem.uuid}`);
          // Note: we must splice instead of assign to trigger render update.
          state.items.value.splice(i, 1, newItem);
        }
      });
    } else {
      Vue.$log.debug(`Adding domain to items: ${newItem.uuid}`);
      state.items.value.push(newItem);
    }
    if (newItem?.uuid !== undefined && state.selectedItem?.value?.uuid === newItem?.uuid) {
      Vue.$log.debug(`Replacing selected item: ${newItem.uuid}`);
      state.selectedItem.value = newItem;
    }
  };

  const getItem = async (uuid: string): Promise<UserModel | undefined> => {
    if (!uuid) {
      const errMessage = 'Cannot get domain without a uuid';
      Vue.$log.error(errMessage);
      return undefined;
    }
    state.isLoading.value = true;
    return axios
      .get<UserModel>(`${apiPath}/${uuid}`)
      .then((res) => {
        Vue.$log.debug('Returned from API: ', res.data);
        if (!res?.data?.uuid) {
          Vue.$log.debug('FAIL! uuid is not in response so cannot add item');
          return res.data;
        }
        addOrReplaceItemInItems(res.data);
        return res.data;
      })
      .catch((err) => {
        const errMessage = 'Error loading.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  return {
    items: computed(() => state.items.value),
    emptyItem: computed(() => emptyItem),
    selectedItem: computed(() => state.selectedItem.value),
    selectedItemId: computed(() => state.selectedItemId.value),
    createdItemId: computed(() => state.createdItemId.value),
    createdItem: computed(() => state.createdItem.value),
    isLoading: computed(() => state.isLoading.value),
    selectItemBy,
    getItem,
    getItems,
    selectItem
  };
}
