

























































































































































import Vue from 'vue';
import { defineComponent, ref, reactive, toRefs, computed } from '@vue/composition-api';
import { formatDbDate, usePagination, saveTextToClipboard } from '@/utils';
import { UserModel, useUserApi } from '@/module/api/user';
import { UserMessageError } from '@/lib/user-message-error';
import { createToastInterface } from 'vue-toastification';

const TABLE_PAGE_SIZE = 50;
const TABLE_SORT_BY = 'five9AgentUsername';
const TABLE_SORT_DESC = false;
const TABLE_SEARCH_FIELD = 'five9AgentUsername';

const itemsPerPageInput = ref(TABLE_PAGE_SIZE);
const sortByInput = ref(TABLE_SORT_BY);
const sortDescInput = ref(TABLE_SORT_DESC);
const searchInput = ref('');

const toast = createToastInterface({ maxToasts: 3 });

const { items, getItems, isLoading } = useUserApi();

const { areThereMorePages } = usePagination(TABLE_PAGE_SIZE);

// Note: we can't use the pagination module's reset because it overwrites data
// which we cannot do.
const emptyPageableTable = () => {
  return {
    itemsPerPage: itemsPerPageInput.value,
    page: 1,
    pageCount: 1,
    sortBy: sortByInput.value,
    sortDesc: sortDescInput.value,
    search: searchInput.value
  };
};

class Filter {
  key!: string;
  value!: string;
}

const table = reactive({
  ...emptyPageableTable(),
  data: items,
  searchField: TABLE_SEARCH_FIELD,
  filter: [] as Filter[],
  loading: computed(() => {
    return isLoading.value;
  }),
  notApplicableValue: '—',
  notConfiguredValue: 'Not Configured',
  filtersAutocomplete: [
    'filter[userId]',
    'filter[organizationId]',
    'filter[customerId]',
    'filter[five9AgentUsername]',
    'filter[state]',
    'filter[roles][$in]'
  ],
  headers: [
    { text: 'Org ID', value: 'organizationId' },
    { text: 'Customer ID', value: 'customerId' },
    { text: 'Five9 Agent Username', value: 'five9AgentUsername' },
    { text: 'Roles', value: 'roles' },
    { text: 'State', value: 'state' },
    { text: 'Created At', value: 'createdAt', sortable: false, width: 75 },
    { text: 'Updated At', value: 'createdAt', sortable: false, width: 75 }
  ] as any
});

const emptyState = () => {
  return {
    isDialogShown: false,
    dialogItem: {} as Partial<UserModel>
  };
};
const state = reactive(emptyState());

const addFilter = () => {
  table.filter.push({ key: '', value: '' });
};

const queryParamFormattedFilter = computed(() => {
  return table.filter.map((rec) => {
    return { [rec.key]: rec.value };
  });
});

const filterAsQueryParams = computed(() => {
  return table.filter.reduce((query: any, rec: any) => {
    if (!rec.key) {
      return query;
    }
    query[rec.key] = rec.value;
    return query;
  }, {});
});

const query = async (): Promise<void> => {
  const query = { ...filterAsQueryParams.value } as any;
  query.page = table.page;
  query.limit = table.itemsPerPage;
  query[`sort[${table.sortBy}]`] = table.sortDesc ? -1 : 1;
  if (table.search) {
    query[`filter[${TABLE_SEARCH_FIELD}][$regex]`] = table.search;
    query[`filter[${TABLE_SEARCH_FIELD}][$options]`] = 'i';
  }
  const queryParams = {
    query,
    raw: true
  };
  Vue.$log.debug('List: query params', queryParams);
  const response = await getItems(queryParams);
  if (response instanceof UserMessageError) {
    toast.error(response.message);
    return;
  }
  if (table.page >= table.pageCount) {
    const areMorePages = areThereMorePages(response);
    if (areMorePages) {
      table.pageCount = table.pageCount + 1;
    }
  }
};

const initializeTableData = async () => {
  Vue.$log.debug('List: initializeTableData');
  Object.assign(table, emptyPageableTable());
  await query();
};

const getNextPage = async () => {
  Vue.$log.debug('List: getnextpage');
  await query();
};

const showDialog = (item: UserModel) => {
  state.dialogItem = item;
  state.isDialogShown = true;
};

const clipyboardCopyWithNotification = (textToCopy: string) => {
  saveTextToClipboard(textToCopy);
  toast.success('Copied text to clipboard.');
};

const jsonPretty = (obj: any) => {
  return JSON.stringify(obj, null, 2);
};

export default defineComponent({
  components: {},
  name: 'UserList',
  data() {
    return {
      options: {} as any
    };
  },
  watch: {
    options: {
      handler() {
        Vue.$log.debug('List: options changed', this.options);
        sortByInput.value = this.options.sortBy[0] ?? TABLE_SORT_BY;
        sortDescInput.value = this.options.sortDesc[0] ?? TABLE_SORT_DESC;
        initializeTableData();
      },
      deep: true
    },
    itemsPerPageInput: function(newVal: number, oldVal: number): void {
      Vue.$log.debug(`List: items per paged chnaged from ${oldVal} to ${newVal}`);
      // Note: emptyTableData will already have the newVal; no assignment needed.
      initializeTableData();
    }
  },
  setup() {
    // Note: becuase the watch.options will trigger on load, we don't need to
    // intialize the table now.
    return {
      ...toRefs(state),
      isLoading: computed(() => isLoading.value),
      itemsPerPageInput,
      searchInput,
      formatDbDate,
      table,
      getNextPage,
      queryParamFormattedFilter,
      addFilter,
      searchTable: () => {
        Vue.$log.debug(`List: search for ${searchInput.value}`);
        // Note: emptyTableData will already have the search value; no
        // assignment needed.
        initializeTableData();
      },
      showDialog,
      clipyboardCopyWithNotification,
      jsonPretty
    };
  }
});
